var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('b-row',{staticClass:"my-3"},[_c('b-col',{attrs:{"sm":"3"}},[_c('UserLinks')],1),_c('b-col',{attrs:{"sm":"9"}},[_c('b-card',[_c('b-tabs',{attrs:{"pills":""},on:{"input":function($event){return _vm.changeTab(_vm.OrderTabs)}},model:{value:(_vm.OrderTabs),callback:function ($$v) {_vm.OrderTabs=$$v},expression:"OrderTabs"}},[_c('b-tab',{attrs:{"title":"الكل","active":""}},[(!this.displayOrderAll)?_c('spinner',{staticClass:"m-3"}):_vm._e(),(this.displayOrderAll)?_c('v-client-table',{attrs:{"data":_vm.AllOrderArr,"columns":_vm.tbcolumn},scopedSlots:_vm._u([{key:"order_detail",fn:function(ref){
var row = ref.row;
return _c('span',{},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-order-details",modifiers:{"modal-order-details":true}}],on:{"click":function($event){return _vm.updateOrderModal(row)}}},[_vm._v("details")])],1)}}],null,false,4259713399)}):_vm._e()],1),_c('b-tab',{attrs:{"title":"المكتملة"}},[(!this.displayOrderCompleted)?_c('spinner',{staticClass:"m-3"}):_vm._e(),(this.displayOrderCompleted)?_c('v-client-table',{attrs:{"data":_vm.CompletedOrdersArr,"columns":_vm.tbcolumn},scopedSlots:_vm._u([{key:"order_detail",fn:function(ref){
var row = ref.row;
return _c('span',{},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-order-details",modifiers:{"modal-order-details":true}}],on:{"click":function($event){return _vm.updateOrderModal(row)}}},[_vm._v("details")])],1)}}],null,false,4259713399)}):_vm._e()],1),_c('b-tab',{attrs:{"title":"بالأنتظار"}},[(!this.displayOrderOnHold)?_c('spinner',{staticClass:"m-3"}):_vm._e(),(this.displayOrderOnHold)?_c('v-client-table',{attrs:{"data":_vm.OnHoldOrderArr,"columns":_vm.tbcolumn},scopedSlots:_vm._u([{key:"order_detail",fn:function(ref){
var row = ref.row;
return _c('span',{},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-order-details",modifiers:{"modal-order-details":true}}],on:{"click":function($event){return _vm.updateOrderModal(row)}}},[_vm._v("details")])],1)}}],null,false,4259713399)}):_vm._e()],1),_c('b-tab',{attrs:{"title":"ملغية"}},[(!this.displayOrderCancelld)?_c('spinner'):_vm._e(),(this.displayOrderCancelld)?_c('v-client-table',{attrs:{"data":_vm.CancelldOrderArr,"columns":_vm.tbcolumn},scopedSlots:_vm._u([{key:"order_detail",fn:function(ref){
var row = ref.row;
return _c('span',{},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-order-details",modifiers:{"modal-order-details":true}}],on:{"click":function($event){return _vm.updateOrderModal(row)}}},[_vm._v("details")])],1)}}],null,false,4259713399)}):_vm._e()],1),_c('b-tab',{attrs:{"title":"قيد التحضير"}},[(!this.displayOrderProcessing)?_c('spinner'):_vm._e(),(this.displayOrderProcessing)?_c('v-client-table',{attrs:{"data":_vm.ProcessingOrderArr,"columns":_vm.tbcolumn},scopedSlots:_vm._u([{key:"order_detail",fn:function(ref){
var row = ref.row;
return _c('span',{},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-order-details",modifiers:{"modal-order-details":true}}],on:{"click":function($event){return _vm.updateOrderModal(row)}}},[_vm._v("details")])],1)}}],null,false,4259713399)}):_vm._e()],1),_c('b-tab',{attrs:{"title":"معلّقة"}},[(!this.displayOrderPending)?_c('spinner'):_vm._e(),(this.displayOrderPending)?_c('v-client-table',{attrs:{"data":_vm.PendingOrderArr,"columns":_vm.tbcolumn},scopedSlots:_vm._u([{key:"order_detail",fn:function(ref){
var row = ref.row;
return _c('span',{},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-order-details",modifiers:{"modal-order-details":true}}],on:{"click":function($event){return _vm.updateOrderModal(row)}}},[_vm._v("details")])],1)}}],null,false,4259713399)}):_vm._e()],1),_c('b-tab',{attrs:{"title":"فاشلة"}},[(!this.displayOrderFaild)?_c('spinner'):_vm._e(),(this.displayOrderFaild)?_c('v-client-table',{attrs:{"data":_vm.FaildOrderArr,"columns":_vm.tbcolumn},scopedSlots:_vm._u([{key:"order_detail",fn:function(ref){
var row = ref.row;
return _c('span',{},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-order-details",modifiers:{"modal-order-details":true}}],on:{"click":function($event){return _vm.updateOrderModal(row)}}},[_vm._v("details")])],1)}}],null,false,4259713399)}):_vm._e()],1)],1),_c('b-modal',{attrs:{"id":"modal-order-details","hide-header":"","hide-footer":""}},[_c('div',{staticClass:"p-3"},[_c('p',[_vm._v(" تم تقديم الطلب #18389 في أغسطس 22, 2021 وهو الآن بحالة ملغي. ")]),_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('b-table',{attrs:{"items":this.OrderItemsArr,"fields":this.OrderItemsFields,"responsive":"","small":""}}),_c('b-table',{attrs:{"items":this.OrderInvoiceDetail,"fields":this.OrderInvoiceDetailFields,"responsive":"","small":"","stacked":"","label-align":"left"}})],1)],1)],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }