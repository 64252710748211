<template>
<div>
        <button class="btn  btn-primary btn-block mb-2"  @click="showConBox" >  {{$t('SignOut')}} </button>

    <b-card>
        <router-link class="btn  btn-block" :to="{name:'User'}" v-bind:class="{ 'btn-primary' : Checklink('User') , 'btn-block': !Checklink('User') }" >  {{$t('MyAcc')}} </router-link>
        <!-- <router-link class="btn  btn-block" :to="{name:'Orders'}" v-bind:class="{ 'btn-primary' : Checklink('Orders') , 'btn-block': !Checklink('Orders') }"  > طلباتي </router-link> -->
        <router-link class="btn  btn-block" :to="{name:'Shipment'}" v-bind:class="{ 'btn-primary' : Checklink('Shipment') , 'btn-block': !Checklink('Shipment') }"  >  {{$t('Shipment')}} </router-link>
        <!-- <router-link class="btn  btn-block" :to="{name:'Billing'}" v-bind:class="{ 'btn-primary' : Checklink('Billing') , 'btn-block': !Checklink('Billing') }" > الفواتير </router-link> -->

    </b-card>
  <div>
      <b-modal
        v-model="show"
        id="modal-center"
        centered
        title=""
        hide-footer
        size="sm"
      >
        <template #modal-header>
          <h3 class="mx-auto text-primary">{{ $t("LOGOUT SUCCESSFUL") }}</h3>
        </template>
        <div class="mx-auto text-center mb-2 mt-2">
          <i class="fa fa-check-circle fa-5x text-primary"></i>
        </div>
      </b-modal>
    </div>
</div>

</template>

<script>
import i18n from '../../main.js'

export default {
    data (){
        return{
              show: false,
        }
    },
   methods:{
       showConBox() {
        this.boxTwo = ''
        this.$bvModal.msgBoxConfirm(i18n.t('You will be logged out, are you sure?'), {
          title:i18n.t('Please Confirm') ,
          size: 'sm',
          buttonSize: 'sm',
         
          bodyClass: 'text-center my-2',
          okVariant: 'danger',
          okTitle: i18n.t('YES'),
          cancelTitle: i18n.t('NO'),
          footerClass: 'p-2',
          hideHeaderClose: true,
          centered: true
        })
          .then(value => {
            if(value){
              this.logout()
            }
            this.boxTwo = value
          })
        
      },
           logout() {
      this.show=true
      setTimeout(() => {
        this.show=false
        this.$router.push({ name: "LogOut" });
      }, 1500);
    },
       Checklink:function(name){

           if(this.$route.name === name){

            return true;
           }else{
               return false
           }
       }

   }
}
</script>

<style>

</style>